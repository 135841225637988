import {
  CakeIcon,
  LightBulbIcon,
  RocketLaunchIcon,
} from '@heroicons/react/20/solid';
import {Card, CardContent, CardHeader, CardTitle} from '~/components/ui/card';
import {Link, useRouteLoaderData} from 'react-router';
import {isFuture, isPast, isWithinInterval} from 'date-fns';

import {Button} from '~/components/ui/button';
import {ClientOnly} from 'remix-utils/client-only';
import {Command} from '~/components/command';
import {Countdown} from '~/components/countdown';
import {Gamepad2Icon} from 'lucide-react';
import {Scramble} from '~/components/scramble';
import {StyledLink} from '~/components/link';
import {TwitchEmbed} from 'react-twitch-embed';
import {TwitchIcon} from '~/components/icons';

type EditionCardProps = React.HTMLAttributes<HTMLDivElement>;

export const EditionCard = ({className}: EditionCardProps) => {
  const data = useRouteLoaderData('root');
  if (!data || !data.currentEdition) return null;
  const {currentEdition, neuchatelAvailable} = data;

  const parts = Intl.DateTimeFormat('en-CH', {
    timeZoneName: 'short',
    timeZone: 'Europe/Zurich',
  }).formatToParts(new Date(currentEdition.startDate));
  const timeZone = parts.find(part => part.type === 'timeZoneName')?.value;

  const editionIsHappening = isWithinInterval(new Date(), {
    start: new Date(currentEdition.startDate),
    end: new Date(currentEdition.endDate),
  });
  const editionIsOver = isPast(new Date(currentEdition.endDate));
  const canSubmitGame = isWithinInterval(new Date(), {
    start: new Date(currentEdition.submissionStartDate),
    end: new Date(currentEdition.submissionEndDate),
  });
  const canSuggestThemes = isWithinInterval(new Date(), {
    start: new Date(currentEdition.suggestionStartDate),
    end: new Date(currentEdition.suggestionEndDate),
  });
  const canRegister = isWithinInterval(new Date(), {
    start: new Date(currentEdition.registrationStartDate),
    end: new Date(currentEdition.registrationEndDate),
  });

  return (
    <>
      <Card className={className} variant="accent">
        <CardHeader>
          <CardTitle className="flex items-center gap-4 text-2xl sm:text-3xl">
            <CakeIcon className="inline-block size-12" />
            <Scramble>
              {editionIsHappening
                ? `${currentEdition.slug} edition is in progress!`
                : editionIsOver
                  ? `${currentEdition.slug} edition is over!`
                  : `${currentEdition.slug} edition is coming soon!`}
            </Scramble>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {!editionIsOver && (
            <ClientOnly>
              {() => (
                <Countdown
                  endDate={
                    editionIsHappening
                      ? new Date(currentEdition.endDate)
                      : new Date(currentEdition.startDate)
                  }
                >
                  <div className="self-end">
                    {editionIsHappening
                      ? 'remaining'
                      : 'before it all starts again'}
                  </div>
                </Countdown>
              )}
            </ClientOnly>
          )}

          {editionIsOver && (
            <>
              <div>
                Once again, this was so epic! Thank you so much and see you next
                time!
              </div>
              {currentEdition.streamDate &&
                isFuture(currentEdition.streamDate) && (
                  <div>
                    All games are played live on{' '}
                    <StyledLink
                      to="https://twitch.tv/epicgamejam"
                      target="_blank"
                      rel="noreferrer noopener"
                      className="font-semibold hover:bg-accent hover:text-background"
                    >
                      Twitch
                    </StyledLink>
                    ! Come and join us for some epic fun!
                  </div>
                )}
            </>
          )}

          {(canSuggestThemes || canSubmitGame) && (
            <div className="flex flex-wrap gap-4">
              {canSuggestThemes && (
                <Button asChild>
                  <Link to="/themes/suggest">
                    <LightBulbIcon className="size-4" />
                    <span>Suggest {currentEdition.slug} subthemes</span>
                    <LightBulbIcon className="size-4" />
                  </Link>
                </Button>
              )}
              {canSubmitGame && (
                <Button asChild variant="positive">
                  <Link to="/submit">
                    <Gamepad2Icon className="size-4" />
                    <span>Submit game</span>
                    <Gamepad2Icon className="size-4" />
                  </Link>
                </Button>
              )}
            </div>
          )}

          {/* Register button */}
          {canRegister && (
            <div className="mt-6 flex flex-col items-start gap-x-6 gap-y-3 text-foreground sm:flex-row sm:items-center">
              <div className="flex shrink-0 flex-col text-center">
                <Button
                  asChild
                  size="lg"
                  className="gap-2.5"
                  variant="outline-foreground"
                >
                  <Link to="birthday">
                    <CakeIcon className="size-5 shrink-0" />
                    <span>Register for Neuchâtel</span>
                    <RocketLaunchIcon className="size-5 shrink-0" />
                  </Link>
                </Button>
                <p className="mt-3 text-xs">
                  (The form is in French but we welcome everyone!)
                </p>
              </div>
              {!neuchatelAvailable ? (
                <p className="md:-mt-5">
                  Or type <Command>register</Command> in the command line below
                </p>
              ) : (
                <div className="bg-foreground px-2 md:-mt-5">
                  <p className="text-background">
                    All seats for Neuchâtel are taken! You can still register to
                    the waiting list though.
                  </p>
                </div>
              )}
            </div>
          )}

          <div>
            <div className="bg-accent px-2 text-2xl font-bold text-background md:text-3xl">
              {Intl.DateTimeFormat('en-CH', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'Europe/Zurich',
              }).formatRange(
                new Date(currentEdition.startDate),
                new Date(currentEdition.endDate)
              )}
            </div>
            <div>
              {Intl.DateTimeFormat('en-CH', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'Europe/Zurich',
              }).formatRange(
                new Date(currentEdition.startDate),
                new Date(currentEdition.endDate)
              )}
              {timeZone && ` (${timeZone})`}
            </div>
            {(editionIsHappening || canSubmitGame) && (
              <h3 className="flex items-center gap-4 bg-[#9146ff] px-4 py-2 text-2xl text-foreground sm:text-3xl">
                <a
                  href="https://twitch.tv/epicgamejam"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="flex items-center gap-4"
                >
                  <TwitchIcon className="size-12 text-foreground" />
                  <div>
                    <Scramble>Watch us live on Twitch!</Scramble>
                    {currentEdition.streamDate && (
                      <div className="text-xl">
                        We'll be playing all {currentEdition.slug} games live on
                        Twitch{' '}
                        {Intl.DateTimeFormat('en-CH', {
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          timeZone: 'Europe/Zurich',
                        }).format(new Date(currentEdition.streamDate))}
                        !
                      </div>
                    )}
                  </div>
                </a>
              </h3>
            )}
          </div>
        </CardContent>
      </Card>

      {(editionIsHappening || (canSubmitGame && editionIsOver)) && (
        <TwitchEmbed
          channel="epicgamejam"
          width="100%"
          autoplay
          withChat
          className={className}
        />
      )}
    </>
  );
};
